import React from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { bindActionCreators } from 'redux';
import { switchLang } from '../actions/lang';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import text from '../data/text';
// import text from '../data/text';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  jpIcon: {
    backgroundImage: `url("${process.env.PUBLIC_URL}/statics/images/jp.svg")`,
    display: 'block',
    backgroundSize: 'cover',
    width: '42px',
    height: '28px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    borderRadius: `8px 8px 8px 8px`,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
      filter: 'brightness(102%)',
    },
  },
  engIcon: {
    backgroundImage: `url("${process.env.PUBLIC_URL}/statics/images/eng.svg")`,
    display: 'block',
    backgroundSize: 'cover',
    width: '42px',
    height: '28px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    borderRadius: `8px 8px 8px 8px`,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
      filter: 'brightness(102%)',
    },
  },
}));
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
HideOnScroll.propTypes = {
  children: PropTypes.any,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.any,
};
function NavBar(props) {
  const classes = useStyles();
  // ダイアログ表示のステート

  // const handleClickSignUpOpen = () => {
  //   // ダイアログ表示してformTextを新規登録にする
  //   setDaialogOpen(true);
  //   setFormText('新規登録');
  // };

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {text.header[props.currentLang]}
            </Typography>
            <div>
              {props.currentLang === 'jp' ? (
                <div onClick={() => props.switchLang(props.currentLang)} value="jp" className={classes.engIcon} />
              ) : (
                <div onClick={() => props.switchLang(props.currentLang)} value="eng" className={classes.jpIcon} />
              )}
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}
NavBar.propTypes = {
  isLoggedIn: PropTypes.any,
  currentLang: PropTypes.any,
  switchLang: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    currentLang: state.lang.currentLang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ switchLang }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
