import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { switchLang } from '../actions/lang';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { SRLWrapper } from 'simple-react-lightbox';
import TextField from '@material-ui/core/TextField';
// import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { vote } from '../actions/vote';
import { TwitterShareButton } from 'react-share';
import text from '../data/text';
import Tooltip from '@material-ui/core/Tooltip';
import { TwitterLoginButton } from 'react-social-login-buttons';
const useStyles = makeStyles({
  root: {
    transition: '1s',
  },
  title: {
    fontSize: 14,
  },
  media: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 'auto',
    height: 'auto',
    maxWidth: '179%' /* 横向き16:9の画像が表示エリア一杯になるように指定 */,
    maxHeight: 'initial',
  },
  mediaWrapper: {
    position: 'relative',
    width: '100%',
    height: '0',
    paddingBottom: '100%' /* 幅に対する縦のサイズ */,
    overflow: 'hidden',
  },
  hidden: {
    visibility: 'hidden',
  },
  fullImage: {
    maxWidth: '100%',
    maxHeight: '80%',
    objectFit: 'contain',
    transition: 'all .3s',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
      filter: 'brightness(102%)',
    },
  },
  card: {
    transition: 'all .3s',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
      filter: 'brightness(102%)',
    },
  },
  modal: {
    height: '50vh',
    width: '80vw',
  },
  pos: {
    marginBottom: 12,
  },
  form: {
    width: '100%',
  },
});

const lightboxOptions = {
  disableKeyboardControls: false,
  lightboxTransitionSpeed: 0.1,
  buttons: {
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: false,
    showPrevButton: false,
    showThumbnailsButton: false,
    size: '40px',
  },
  thumbnails: {
    showThumbnails: false,
  },
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const MultilineTextFields = (props) => {
  const classes = useStyles();
  const { value, onChange, status } = props;
  const data = props.props;
  return (
    <div className={classes.form}>
      <form noValidate autoComplete="off">
        <TextField
          id="outlined-multiline-flexible"
          placeholder={text.comment[data.currentLang]}
          multiline
          rowsMax={8}
          onChange={onChange}
          variant="outlined"
          value={value}
          fullWidth
          error={status}
          helperText={status ? text.commentError[data.currentLang] : ''}
        />
      </form>
    </div>
  );
};
MultilineTextFields.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.any,
  status: PropTypes.any,
  props: PropTypes.any,
  data: PropTypes.any,
};

const TweetButton = ({ props }) => {
  const url = `https://${location.host}?No=${props.entry.entryNo}`;

  const tweetText =
    props.currentLang === 'jp'
      ? `『${props.entry.title}』 by ${props.entry.artistName} `
      : `"${props.entry.title}" by ${props.entry.artistName} `;

  return (
    <Grid item xs={12}>
      <TwitterShareButton url={url} title={tweetText} hashtags={['JMoF2021', 'JFAC2021']}>
        {/* <TwitterIcon size={20} round /> */}
        <TwitterLoginButton align="center" iconSize={'12px'} size={'18px'}>
          <span style={{ fontSize: 12, verticalAlign: 'middle' }}>{text.tweetButton[props.currentLang]}</span>
        </TwitterLoginButton>
      </TwitterShareButton>
    </Grid>
  );
};
TweetButton.propTypes = {
  props: PropTypes.any,
  entry: PropTypes.any,
  entryNo: PropTypes.any,
  currentLang: PropTypes.any,
  title: PropTypes.any,
  artistName: PropTypes.any,
};

const Entry = (props) => {
  const { entry } = props;
  let { entryNo, artistName, artistNameEng, image } = entry;
  const classes = useStyles();
  React.useEffect(() => {
    let urlParamStr = window.location.search;
    if (urlParamStr) {
      //?を除去
      urlParamStr = urlParamStr.substring(1);

      let params = {};

      //urlパラメータをオブジェクトにまとめる
      urlParamStr.split('&').forEach((param) => {
        const temp = param.split('=');
        //pramsオブジェクトにパラメータを追加
        params = {
          ...params,
          [temp[0]]: temp[1],
        };
      });
      if (params.No === entryNo) {
        setOpen(true);
      }
    }
  }, []);

  if ((artistName === '') & (artistNameEng !== '')) {
    artistName = artistNameEng;
  }
  if ((artistNameEng === '') & (artistName !== '')) {
    artistNameEng = artistName;
  }
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const [isLoaded, setLoadedStatus] = React.useState(false);
  const [isValidationError, toggleValidationErrorStatus] = React.useState(false);
  const handleChange = (event) => {
    setComment(event.target.value);
    if (comment !== '') {
      //console.log(comment.length);
      if (comment.length >= 1000) {
        toggleValidationErrorStatus(true);
      } else if (comment.length < 1000) {
        toggleValidationErrorStatus(false);
      }
    }
  };

  const submitForm = (props, entryNo) => {
    const data = {
      isVoted: true,
      voteInfo: {
        voteTo: entryNo,
        comment: comment,
        user: props.user.providerData[0].uid,
      },
    };
    //console.log(data);
    props.vote(data);
  };
  const setLoaded = () => {
    if (!isLoaded) {
      setLoadedStatus(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    setLoaded();
  });
  return (
    <Fragment>
      <Card className={`${classes.root} ${classes.card}`} onClick={handleClickOpen}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            No.{entryNo}
          </Typography>
          <Typography variant="h6" component="h5" noWrap style={{ width: '100%' }}>
            {props.currentLang === 'jp' ? artistName : artistNameEng}
          </Typography>
        </CardContent>
        <div className={classes.mediaWrapper}>
          {isLoaded ? (
            <img
              className={classes.media}
              src={process.env.PUBLIC_URL + `/resources/images/${image}`}
              onLoad={setLoaded}
            />
          ) : (
            <Box className={classes.media} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          )}
        </div>
      </Card>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Tooltip placement="right" title={props.currentLang === 'jp' ? 'クリックして拡大' : 'Click to expand'}>
              <Grid item xs={6}>
                <SRLWrapper options={lightboxOptions}>
                  <img src={process.env.PUBLIC_URL + `/resources/images/${image}`} className={classes.fullImage} />
                </SRLWrapper>
              </Grid>
            </Tooltip>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item xs={7}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        No.{entryNo}
                      </Typography>
                    </Grid>

                    {/* <Grid item xs={5}>
                      <TweetButton props={props} />
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h2">
                    {props.currentLang === 'jp' ? artistName : artistNameEng}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <MultilineTextFields
                    id={`form_${Math.random()}`}
                    value={comment}
                    onChange={handleChange}
                    status={isValidationError}
                    props={props}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" disableElevation>
            {text.cancelOpen[props.currentLang]}
          </Button>
          {props.isLoggedIn ? (
            <Button
              disabled={isValidationError}
              variant="contained"
              disableElevation
              onClick={() => {
                submitForm(props, entryNo);
              }}
              color="primary"
            >
              {text.vote[props.currentLang]}
            </Button>
          ) : (
            <Button disabled="true" variant="contained" color="primary" disableElevation>
              {text.nologinnovote[props.currentLang]}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
Entry.propTypes = {
  isLoggedIn: PropTypes.any,
  currentLang: PropTypes.any,
  switchLang: PropTypes.any,
  entry: PropTypes.any,
  entryNo: PropTypes.any,
  artistName: PropTypes.any,
  artistNameEng: PropTypes.any,
  title: PropTypes.any,
  titleEng: PropTypes.any,
  twitter: PropTypes.any,
  pixiv: PropTypes.any,
  image: PropTypes.any,
  vote: PropTypes.any,
  voteInfo: PropTypes.any,
  user: PropTypes.any,
  isValidationError: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    currentLang: state.lang.currentLang,
    voteInfo: state.vote.voteInfo,
    isVoted: state.vote.isVoted,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ vote }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
