import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import text from '../data/text';
import { db } from '../config/firebase';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import entries from '../data/entries';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '100%',
  },
  loading: {
    height: '100%',
    width: '100%',
    background: 'rgba(0,0,0,0.4)',
    position: 'fixed',
    top: '0px',
    left: '0px',
    zIndex: '100',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

//ログイン後のページ
const UserInfo = (props) => {
  const [votedInfo, setVotedInfo] = React.useState(null);
  const { isLoading } = props;
  React.useEffect(() => {
    if (props.user && !votedInfo) {
      const docId = props.user.providerData[0].uid;
      const docRef = db.collection('Votes').doc(docId);
      docRef.get().then((doc) => {
        setVotedInfo(doc.data());
      });
    }
  });

  const classes = useStyles();

  if (isLoading) {
    //リダイレクト時、読み込み中の場合
    return (
      <Box className={`${classes.loading}`}>
        <CircularProgress color="primary" />
      </Box>
    );
  } else {
    return props.isLoggedIn ? (
      !props.isVoted ? (
        <Card className={`${classes.card}`}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar} src={props.user.providerData[0].photoURL}></Avatar>
            }
            title={`${text.loggedIn[props.currentLang]}${props.user.providerData[0].displayName}`}
            subheader={
              votedInfo
                ? `${text.voteStatus[props.currentLang]}No.${votedInfo.voteTo}`
                : `${text.clickArtWhatYouVoteFor[props.currentLang]}`
            }
          />
          <CardMedia
            className={classes.media}
            image={
              votedInfo
                ? `${process.env.PUBLIC_URL}/resources/images/${entries[votedInfo.voteTo - 1].image}`
                : `${process.env.PUBLIC_URL}/resources/images/dummy.jpg`
            }
          />
        </Card>
      ) : null
    ) : (
      <Card className={`${classes.card}`}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar} src={`${process.env.PUBLIC_URL}/resources/images/app-icon.png`}></Avatar>
          }
          title={props.currentLang === 'jp' ? '未ログイン' : 'Not Loggedin'}
          subheader={props.currentLang === 'jp' ? '投票できません' : "You can't vote"}
        />
        <CardMedia className={classes.media} image={`${process.env.PUBLIC_URL}/resources/images/dummy.jpg`} />
      </Card>
    );
  }
};

UserInfo.propTypes = {
  isLoggedIn: PropTypes.any,
  user: PropTypes.any,
  currentLang: PropTypes.any,
  props: PropTypes.any,
  isVoted: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    currentLang: state.lang.currentLang,
    voteInfo: state.vote.voteInfo,
    isVoted: state.vote.isVoted,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(UserInfo);
