import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import { Grid } from '@material-ui/core';
import { vote } from '../actions/vote';
import text from '../data/text';
import Button from '@material-ui/core/Button';
// import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
  root: {},
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const returnTop = () => {
  location.href = '/';
};

const End = (props) => {
  // const classes = useStyles();
  // history.push('/about');
  const classes = useStyles();
  if (props.isLoggedIn) {
    return (
      <Grow in={props.isVoted} {...(props.isVoted ? { timeout: 1000 } : {})}>
        <Grid container>
          <Grid xs={12} item>
            <Grid container spacing={1} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={8}>
                <div style={{ textAlign: 'center' }}>
                  <Typography className={classes.title} color="textSecondary" variant="h4">
                    {text.voted[props.currentLang]}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={8}>
                <div className={classes.divCenter}>
                  <Button
                    onClick={() => {
                      returnTop(props);
                    }}
                    color="primary"
                    disableElevation
                  >
                    {text.returnToTopPage[props.currentLang]}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    );
  } else {
    return <div>投票にはTwitterログインが必要です</div>;
  }
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    currentLang: state.lang.currentLang,
    voteInfo: state.vote.voteInfo,
    isVoted: state.vote.isVoted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ vote }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(End);
