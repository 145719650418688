import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import firebase, { db } from '../config/firebase';
import Grid from '@material-ui/core/Grid';
import { vote } from '../actions/vote';
import Typography from '@material-ui/core/Typography';
import text from '../data/text';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import entries from '../data/entries';
// const useStyles = makeStyles({
//   root: {},
// });
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {},
  media: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 'auto',
    height: 'auto',
    maxWidth: '179%' /* 横向き16:9の画像が表示エリア一杯になるように指定 */,
    maxHeight: 'initial',
  },
  mediaWrapper: {
    position: 'relative',
    width: '100%',
    height: '0',
    paddingBottom: '100%' /* 幅に対する縦のサイズ */,
    overflow: 'hidden',
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const submitForm = async (props, toggleIsSending) => {
  const docId = props.voteInfo.user;
  toggleIsSending(true);
  await db.collection('Votes').doc(docId).set({
    docId: docId,
    voteTo: props.voteInfo.voteTo,
    comment: props.voteInfo.comment,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
  toggleIsSending(false);
  const data = {
    isVoted: 'voted',
  };
  props.vote(data);
};

const cancelVote = (props) => {
  const data = {
    isVoted: 'canceled',
    voteInfo: {
      voteTo: null,
      comment: props.voteInfo.comment,
      user: props.voteInfo.user,
    },
  };
  props.vote(data);
};

const Submit = (props) => {
  const classes = useStyles();
  const [isSending, toggleIsSending] = React.useState(false);
  // history.push('/about');
  if (props.isLoggedIn) {
    return (
      <Grow in={props.isVoted} {...(props.isVoted ? { timeout: 1000 } : {})}>
        <Grid container>
          <Grid xs={12} item>
            <Grid container spacing={1} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={8}>
                <div style={{ textAlign: 'center' }}>
                  <Typography className={classes.title} color="textSecondary" variant="h6">
                    {text.confirmation[props.currentLang]}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={8}>
                <div style={{ textAlign: 'center' }}>
                  <Typography className={classes.title} color="textSecondary">
                    {text.notSubmittedYet[props.currentLang]}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={8}>
                <Card className={`${classes.root} ${classes.card}`}>
                  <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                    <Grid item xs={12}>
                      <CardContent>
                        <div style={{ textAlign: 'center' }}>
                          <Typography className={classes.title} color="textSecondary" variant="h5">
                            No.{props.voteInfo.voteTo}
                          </Typography>
                        </div>
                      </CardContent>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div style={{ padding: '3%' }}>
                        <div className={classes.mediaWrapper}>
                          <img
                            className={classes.media}
                            src={`${process.env.PUBLIC_URL}/resources/images/${
                              entries[props.voteInfo.voteTo - 1].image
                            }`}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CardContent>
                        <Typography className={classes.title} color="textPrimary">
                          {props.voteInfo.comment ? props.voteInfo.comment : text.noComment[props.currentLang]}
                        </Typography>
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ marginTop: '3vh' }}
                >
                  <Grid item xs={4}>
                    <div className={classes.divCenter}>
                      <Button onClick={() => cancelVote(props)} disableElevation variant="contained">
                        {text.cancelSubmit[props.currentLang]}
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.divCenter}>
                      <Button
                        onClick={() => {
                          submitForm(props, toggleIsSending);
                        }}
                        disableElevation
                        disable={isSending}
                        color="primary"
                        variant="contained"
                      >
                        {text.submit[props.currentLang]}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    );
  } else {
    return <div>投票にはTwitterログインが必要です</div>;
  }
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    currentLang: state.lang.currentLang,
    voteInfo: state.vote.voteInfo,
    isVoted: state.vote.isVoted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ vote }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Submit);
