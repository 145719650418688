import React, { Component, Fragment } from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from './config/firebase';
import PropTypes from 'prop-types';
import { loggined, logouted } from './actions';
import history from './history';
import UserInfo from './components/UserInfo';
import NavBar from './components/NavBar';
import LandingPage from './components/LandingPage';
// import Auth from './Auth';
import About from './components/About';
import LoginButton from './components/LoginButton';
import SimpleReactLightbox from 'simple-react-lightbox';
// import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
const Spacer = () => {
  const theme = useTheme();
  //console.log(theme.mixins.toolbar);
  const useStyles = makeStyles({
    toolbar: theme.mixins.toolbar,
    spaceBottom: {
      marginBottom: '30px',
    },
  });
  const classes = useStyles();
  return <div className={`${classes.toolbar} ${classes.spaceBottom}`} />;
};
Spacer.propTypes = {
  children: PropTypes.any,
};

class App extends Component {
  constructor() {
    super();
    this.state = { isLoading: true };
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        //console.log('resultコールバックが動いている');
        if (result.credential) {
          //console.log('よみこみ');
          this.setState({ isLoading: false });
        } else {
          //console.log('no result here !!!!!!!!!!!!!!!!!');
          this.setState({ isLoading: false });
        }
      });
    firebase.auth().onAuthStateChanged((user) => {
      //console.log(user);
      if (user) {
        // ログイン処理
        //console.log('logineeeee');
        this.props.loggined();
      } else {
        // ログアウト処理
        //console.log('bye');
        this.props.logouted();
      }
    });
  }
  render() {
    return (
      <Fragment>
        <NavBar />
        <SimpleReactLightbox>
          <Spacer />
          <Container>
            <About />
            <Grid container spacing={1} justify="center" alignItems="center">
              <Grid item xs={12}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <LoginButton />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <UserInfo isLoading={this.state.isLoading} />
                  </Grid>
                </Grid>
              </Grid>

              <Router history={history}>
                <Grid item xs={12} sm={12}>
                  <Route path="/" exact component={LandingPage} />
                </Grid>
              </Router>
            </Grid>
          </Container>
        </SimpleReactLightbox>
      </Fragment>
    );
  }
}

App.propTypes = {
  loggined: PropTypes.any,
  logouted: PropTypes.any,
};

const mapStateToProps = (state) => {
  return { isLoggedIn: state.auth.isLoggedIn };
};

export default connect(mapStateToProps, { loggined, logouted })(App);
