import { LANG } from '../actions/types';

const INITIAL_STATE = {
  currentLang: 'jp',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LANG:
      //langをswitch
      if (action.payload === 'jp') {
        return {
          ...state,
          currentLang: 'en',
        };
      } else {
        return {
          ...state,
          currentLang: 'jp',
        };
      }

    default:
      return state;
  }
};
