import React from 'react';
import { connect } from 'react-redux';
import { loggined } from '../actions';
import PropTypes from 'prop-types';
import entries from '../data/entries';
import Entry from './Entry';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import text from '../data/text';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
  },
  item: {
    padding: theme.spacing(1),
  },
  space: {
    marginTop: '4vh',
  },
}));

const Entries = (props) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} direction="row" justify="space-around" alignItems="center">
      <Grid item xs={12} className={classes.space}>
        <Grid container spacing={1} direction="row" justify="space-around" alignItems="center">
          <Grid item xs={4}>
            <Typography className={classes.title} color="textSecondary" variant="h5">
              {text.step2[props.currentLang]}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} direction="row" justify="space-around" alignItems="center">
          <Grid item xs={4}>
            <Typography className={classes.title} color="textSecondary">
              {text.clickArtWhatYouVoteFor[props.currentLang]}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center" spacing={5} className={`${classes.root}`}>
        {entries.map((item, i) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`entry_${i}`}>
              <Entry entry={item} className={classes.item} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    currentLang: state.lang.currentLang,
    voteInfo: state.vote.voteInfo,
    isVoted: state.vote.isVoted,
  };
};

Entries.propTypes = {
  currentLang: PropTypes.any,
  isVoted: PropTypes.any,
};

export default connect(mapStateToProps, {
  loggined,
})(Entries);
