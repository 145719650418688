const text = {
  header: { jp: 'イラストコンテスト用投票ページサンプル', en: 'Sample Audience Vote Page for Art Competition' },
  about: {
    jp:
      'こちらは「イラストコンテスト」の投票ページです。以下の注意事項をご確認のうえ、あなたが最も「侍」のテーマに即していると思う作品に投票してください。',
    en:
      'This is the “Art Competition” vote page. Please confirm the following notes and vote for one artwork that you judge to be the most appropriate to the theme: “Samurai”.',
  },
  loggedIn: { jp: 'ログイン中：', en: 'You are already logged in as' },
  voteStatus: { jp: 'すでに投票済みです：', en: 'You have already voted: ' },
  notice: { jp: '注意事項', en: 'Important Notes: ' },
  twitterNeeded: {
    jp:
      '投票には、Twitterアカウントとの連携が必要です。これには1分程度かかる場合があります。連携がうまくいかない場合は、お手数ですが別のブラウザーを使うなど環境を変えてお試しください。',
    en:
      'In order to vote you must connect your Twitter account first. It may take a minute. When it failed, please try to change the environment (ex. Use other browsers).',
  },
  voteIsOnceOnly: {
    jp: '投票は1人1票までです。投票を締め切るまで、何度でも投票内容を変更することができます。',
    en: 'One vote per person. You can change what you vote during the voting period at any time.',
  },
  whenWeDeclineVote: {
    jp:
      '1人で複数のTwitterアカウントを使用するなど、不正な投票であると判断した場合、該当の投票を無効にする場合があります。',
    en:
      'If we discover illegitimate votes such as votes from one person using multiple Twitter accounts we may render those votes invalid.',
  },
  step1: { jp: 'Step 1', en: 'Step 1' },
  explanation1: { jp: 'Twitterアカウントでログインしてください。', en: 'Login with your Twitter account.' },
  loginWithTwitter: { jp: 'Twitterにログイン', en: 'Login to Twitter' },
  logoutFromTwitter: { jp: 'Twitterからログアウト', en: 'Logout from Twitter' },
  notLoggedIn: { jp: '未ログイン', en: 'You are not logged in' },
  youNeedToLoginToVote: { jp: '投票できる状態になりました', en: 'You can vote now' },
  step2: { jp: 'Step 2', en: 'Step 2' },
  clickArtWhatYouVoteFor: {
    jp: '投票したい作品をクリックしてください。',
    en: 'Please click on the artwork you wish to vote for.',
  },
  entryNo: { jp: 'No. XXX', en: 'No. XXX' },
  title: { jp: '作品名', en: 'Artwork Name' },
  author: { jp: '作者名', en: 'Artist' },
  comment: { jp: '作者の方へのコメント（任意）', en: 'Comments to the Artist (Optional)' },
  commentError: { jp: '1,000文字以下で入力してください', en: 'Max. 1,000 characters' },
  cancelOpen: { jp: 'キャンセル', en: 'Cancel' },
  vote: { jp: '投票する', en: 'Vote' },
  nologinnovote: {
    jp: '投票には、Twitterアカウントとの連携が必要です。',
    en: 'In order to vote you must connect your Twitter account first.',
  },
  tweetButton: { jp: 'ツイート', en: 'Tweet' },
  modalTweet: { jp: '[作者名]による作品', en: 'The work by [Artist]' },
  confirmation: {
    jp: '以下の内容で投票します。よろしいですか？',
    en: 'You will vote with the following information. Is this OK?',
  },
  notSubmittedYet: { jp: '※まだ投票は完了していません。', en: 'Note: Your vote is not submitted yet.' },
  confirmComment: { jp: '作者の方へのコメント', en: 'Comments to the Artist' },
  noComment: { jp: 'コメントなし', en: 'A comment is not submitted.' },
  cancelSubmit: { jp: 'キャンセル', en: 'Cancel' },
  submit: { jp: '確定する', en: 'Submit' },
  voted: { jp: '投票が完了しました！', en: 'Your vote has been submitted!' },
  returnToTopPage: { jp: 'トップページに戻る', en: 'Return to the Top Page' },
  tweetVote: { jp: 'イラストコンテストに投票しました！', en: 'I voted in the Art Competition!' },
};
export default text;
