import React from 'react';
import { connect } from 'react-redux';
import Submit from './Submit';
import Entries from './Entries';
import End from './End';

function LandingPage(props) {
  if (props.isVoted === true && props.isVoted !== 'done') {
    return <Submit />;
  } else if (props.isVoted === 'voted') {
    return <End />;
  } else {
    return <Entries />;
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    currentLang: state.lang.currentLang,
    voteInfo: state.vote.voteInfo,
    isVoted: state.vote.isVoted,
  };
};

export default connect(mapStateToProps)(LandingPage);
