const entries = [
  {
    entryNo: '1',
    lang: 'jp',
    artistName: 'あおなん',
    artistNameEng: 'aonan',
    title: 'タイトル1',
    titleEng: 'Title1',
    image: '1.jpg',
    twitter: 'hoge',
    pixiv: '',
  },
  {
    entryNo: '2',
    lang: 'jp',
    artistName: '雪代ゆきし',
    artistNameEng: 'YUKISHIRO YUKISHI',
    title: 'タイトル2',
    titleEng: 'Title2',
    image: '2.jpg',
    twitter: 'hoge',
    pixiv: '',
  },
  {
    entryNo: '3',
    lang: 'jp',
    artistName: '犬耳　もえ太',
    artistNameEng: 'Moeta Inumimi',
    title: 'タイトル3',
    titleEng: 'Title3',
    image: '3.jpg',
    twitter: 'hoge',
    pixiv: '',
  },
  {
    entryNo: '4',
    lang: 'jp',
    artistName: 'のびる',
    artistNameEng: 'no_biru',
    title: 'タイトル4',
    titleEng: 'Title4',
    image: '4.jpg',
    twitter: 'hoge',
    pixiv: '',
  },
  {
    entryNo: '5',
    lang: 'jp',
    artistName: '声',
    artistNameEng: 'koe',
    title: 'タイトル5',
    titleEng: 'Title5',
    image: '5.jpg',
    twitter: 'hoge',
    pixiv: '',
  },
  {
    entryNo: '6',
    lang: 'jp',
    artistName: '梨味',
    artistNameEng: 'Nashiazi',
    title: 'タイトル6',
    titleEng: 'Title6',
    image: '6.jpg',
    twitter: 'hoge',
    pixiv: '',
  },
  {
    entryNo: '7',
    lang: 'jp',
    artistName: 'ジェスタ',
    artistNameEng: 'Jesta',
    title: 'タイトル7',
    titleEng: 'Title7',
    image: '7.jpg',
    twitter: 'hoge',
    pixiv: '',
  },
  {
    entryNo: '8',
    lang: 'jp',
    artistName: '夏ツナ',
    artistNameEng: 'Natutuna',
    title: 'タイトル8',
    titleEng: 'Title8',
    image: '8.jpg',
    twitter: 'hoge',
    pixiv: '',
  },
  {
    entryNo: '9',
    lang: 'jp',
    artistName: 'わんきち',
    artistNameEng: 'Wankichi',
    title: 'タイトル9',
    titleEng: 'Title9',
    image: '9.jpg',
    twitter: 'hoge',
    pixiv: '',
  },
  {
    entryNo: '10',
    lang: 'jp',
    artistName: 'tamanosuke',
    artistNameEng: 'tamanosuke',
    title: 'タイトル10',
    titleEng: 'Title10',
    image: '10.jpg',
    twitter: 'hoge',
    pixiv: '',
  },
];
export default entries;
