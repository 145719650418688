import { VOTEINFO } from '../actions/types';

const INITIAL_STATE = {
  isVoted: false,
  voteInfo: {
    voteTo: null,
    comment: null,
    userId: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  //console.log('here', action.payload);
  switch (action.type) {
    case VOTEINFO:
      //langをswitch
      if (action.payload.isVoted === true && action.payload.isVoted !== 'canceled') {
        //console.log('受け取った値', action.payload);
        return {
          ...state,
          isVoted: true,
          voteInfo: action.payload.voteInfo,
        };
      } else if (action.payload.isVoted === 'canceled') {
        //console.log('キャンセルします');
        return {
          ...state,
          isVoted: false,
          voteInfo: action.payload.voteInfo,
        };
      } else if (action.payload.isVoted === 'voted') {
        //console.log('投票完了');
        return {
          ...state,
          isVoted: 'voted',
        };
      } else if (action.payload.isVoted === 'returnTop') {
        //console.log('投票完了');
        return {
          ...state,
          isVoted: false,
        };
      }
      break;
    default:
      return state;
  }
};
