import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import SwitchLang from './SwitchLang';
import Vote from './Vote';

export default combineReducers({
  auth: AuthReducer,
  lang: SwitchLang,
  vote: Vote,
});
