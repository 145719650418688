import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loggined } from '../actions';
import { TwitterLoginButton } from 'react-social-login-buttons';
import firebase from '../config/firebase';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import text from '../data/text';
class LoginButton extends Component {
  twitterLogin = () => {
    // ツイッターログイン処理
    const provider = new firebase.auth.TwitterAuthProvider();
    firebase
      .auth()
      .signInWithRedirect(provider)
      .then(() => {
        //console.log('TwitterLoginしました');
        //console.log(result);
      });
  };

  logout = () => {
    // firebaseからのログアウト処理
    firebase
      .auth()
      .signOut()
      .then(() => {
        //console.log('ログアウトしました');
      })
      .catch(() => {
        //console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
  };

  render() {
    return !this.props.isVoted ? (
      <div style={{ margin: '6vh,0,2vh,0' }}>
        <Typography color="textSecondary" variant="h5">
          {text.step1[this.props.currentLang]}
        </Typography>
        <Typography color="textSecondary">{text.explanation1[this.props.currentLang]}</Typography>
        {this.props.isLoggedIn ? (
          <TwitterLoginButton onClick={this.logout} align="center" iconSize={'20'} size={'40'}>
            <span style={{ fontSize: 16 }}>{text.logoutFromTwitter[this.props.currentLang]}</span>
          </TwitterLoginButton>
        ) : (
          <TwitterLoginButton onClick={this.twitterLogin} align="center" iconSize={'20'} size={'40'}>
            <span style={{ fontSize: 16 }}>{text.loginWithTwitter[this.props.currentLang]}</span>
          </TwitterLoginButton>
        )}
      </div>
    ) : null;
  }
}

// Reduxと繋げる
const mapStateToProps = ({ auth, vote, lang }) => {
  return {
    isLoggedIn: auth.isLoggedIn,
    user: auth.user,
    isVoted: vote.isVoted,
    currentLang: lang.currentLang,
  };
};

LoginButton.propTypes = {
  isLoggedIn: PropTypes.any,
  user: PropTypes.any,
  isVoted: PropTypes.any,
  currentLang: PropTypes.any,
};

export default connect(mapStateToProps, {
  loggined,
})(LoginButton);
