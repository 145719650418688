import React from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { switchLang } from '../actions/lang';
import { makeStyles } from '@material-ui/core/styles';
// import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import text from '../data/text';
const useStyles = makeStyles({
  root: {
    transition: '1s',
  },
  title: {
    fontSize: 14,
  },
  media: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 'auto',
    height: 'auto',
    maxWidth: '179%' /* 横向き16:9の画像が表示エリア一杯になるように指定 */,
    maxHeight: 'initial',
  },
  mediaWrapper: {
    position: 'relative',
    width: '100%',
    height: '0',
    paddingBottom: '100%' /* 幅に対する縦のサイズ */,
    overflow: 'hidden',
  },
  hidden: {
    visibility: 'hidden',
  },
  fullImage: {
    maxWidth: '100%',
    maxHeight: '80%',
    objectFit: 'contain',
    transition: 'all .3s',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
      filter: 'brightness(102%)',
    },
  },
  card: {
    transition: 'all .3s',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
      filter: 'brightness(102%)',
    },
  },
  modal: {
    height: '50vh',
    width: '80vw',
  },
  pos: {
    marginBottom: 12,
  },
  form: {
    width: '100%',
  },
});

const About = (props) => {
  const classes = useStyles();
  return !props.isVoted ? (
    <Container>
      <Typography className={classes.title} color="textPrimary" gutterBottom>
        {text.about[props.currentLang]}
      </Typography>
      <Typography color="textSecondary">{text.notice[props.currentLang]}</Typography>
      <ul>
        <li> {text.twitterNeeded[props.currentLang]}</li>
        <li> {text.voteIsOnceOnly[props.currentLang]}</li>
        <li> {text.whenWeDeclineVote[props.currentLang]}</li>
      </ul>
    </Container>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    currentLang: state.lang.currentLang,
    voteInfo: state.vote.voteInfo,
    isVoted: state.vote.isVoted,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(About);
